<template>
  <div class="BlogList container" itemscope itemtype="https://schema.org/ItemList">
    <div class="row mb-4">
      <div class="col-12">
        <h1><span class="font-family-krona font-size-150">Blog</span></h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-12 mb-4" v-for="(blog,index) in blogs" v-bind:key="index" itemprop="itemListElement" itemscope itemtype="https://schema.org/BlogPosting">
        <router-link class="no-link" :to="{ name: 'BlogDetail', params: { slug: blog.slug}}" itemprop="url">
          <div class="card">
            <img class="card-img-top" :src="blog.content.Thumbnail" alt="Card image cap" itemprop="image">
            <div class="card-body">
              <h2 class="card-title h4" itemprop="headline">{{ blog.content.Title }}</h2>
              <span class="sr-only" itemprop="author">Dxboard</span>
              <p class="card-text mb-0 text-gray" itemprop="description">{{ blog.content.Short_Description }}</p>
              <small class="text-gray font-size-075">Published at <span class="text-primary" itemprop="datePublished" :content="blog.first_published_at">{{$formatDateWithTime(blog.first_published_at)}}</span></small>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'BlogList',
  data() {
    return {
      blogs: []
    };
  },
  created() {
    axios.get(`https://api.storyblok.com/v2/cdn/stories?token=39VzSHOswQcS3rnQER7nrQtt&starts_with=blog`)
      .then(async (response)=> {
        this.blogs = response.data.stories;
        this.$emit('widgetLoaded');
      });
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .BlogList{
    .card-title{
      height: 58px;
      overflow: hidden;
    }
    .card-text{
      height: 72px;
      overflow: hidden;
    }
  }
</style>
